<template>
    <div class="Sms1">
        <div class="form-search-box flex-row-start flex-wrap">
            <div>短信签名管理</div>
            <a-button class="ml20" icon="plus" @click="onAdd({})" type="primary">添加签名</a-button>
            <el-tag class="ml20" size="medium">说明：如果创建平台签名，其它品牌未创建签名，则统一用平台签名；如果其它品牌创建了签名，则优先用品牌签名。</el-tag>
        </div>
        <div class="form-search-box flex-row-start flex-wrap mt10">
            <div class="flex-row-start" style="margin-right:20px">
                <div style="font-size: 14px;color: #606266;font-weight: 700;margin-right:10px">签名:</div>
                <el-input size="mini" style="width:200px" v-model="search.name" placeholder="输入签名" />
            </div>
            <div class="flex-row-start" style="margin-right:20px">
                <div style="font-size: 14px;color: #606266;font-weight: 700;margin-right:10px">门店:</div>
                <el-input size="mini" style="width:200px" v-model="search.var" placeholder="门店连锁代码/编号/名称" />
            </div>
            <div class="flex-row-start" style="margin-right:20px">
                <div style="font-size: 14px;color: #606266;font-weight: 700;margin-right:10px">审核:</div>
                <el-select size="mini" style="width:200px" v-model="search.status" filterable placeholder="请选择审核">
                    <el-option v-for="(item,index) in statusList" :key="index" :label="item.title" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div class="flex-row-start" style="margin-right:20px">
                <div style="font-size: 14px;color: #606266;font-weight: 700;margin-right:10px">状态:</div>
                <el-select size="mini" style="width:200px" v-model="search.auditing_status" filterable placeholder="请选择状态">
                    <el-option v-for="(item,index) in auditingStatus" :key="index" :label="item.title" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div class="flex-row-start">
                <a-button @click="getLists(1)" type="primary">查 询</a-button>
                <a-button @click="onReset" class="ml10">重 置</a-button>
            </div>
        </div>
        <div class="table-container mt20">
            <div class="wxb-table-white">
                <el-table v-loading="loading" ref="multipleTable" :data="tableData" fit highlight-current-row>
                    <el-table-column align="center" label="ID" width="80">
                        <template slot-scope="scope">
                            {{ scope.row.id? scope.row.id:'-'}}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="签名名称" minWidth="100">
                        <template slot-scope="scope">
                            【{{ scope.row.name? scope.row.name:'-'}}】
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="主门店名称" minWidth="100">
                        <template slot-scope="scope">
                            {{ scope.row.shop_code? scope.row.shop_code:'-'}} - {{ scope.row.shop_name? scope.row.shop_name:'-'}}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="资质类目" minWidth="100">
                        <template slot-scope="scope">
                            {{ nameCategory(scope.row.category)}}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="资质图片" minWidth="100">
                        <template slot-scope="scope">
                            <img @click="handleView(scope.row.file_url)" class="file_url" :src="scope.row.file_url" alt="">
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="备注" minWidth="100">
                        <template slot-scope="scope">
                            {{ scope.row.remarks? scope.row.remarks:'-'}}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="审核状态" minWidth="100">
                        <template slot-scope="scope">
                            <span style="color:#409EFF" v-if="scope.row.auditing_status == 1">待审核</span>
                            <span style="color:#67C23A" v-if="scope.row.auditing_status == 2">已通过</span>
                            <span style="color:#F56C6C" v-if="scope.row.auditing_status == 3">已驳回</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="状态" minWidth="100">
                        <template slot-scope="scope">
                            <span style="color:#409EFF" v-if="scope.row.status == 1">待审核</span>
                            <span style="color:#67C23A" v-if="scope.row.status == 2">已启用</span>
                            <span style="color:#F56C6C" v-if="scope.row.status == 3">已禁用</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="创建时间" minWidth="120">
                        <template slot-scope="scope">
                            {{ scope.row.create_time? scope.row.create_time:'-'}}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" minWidth="90">
                        <template slot-scope="scope">
                            <el-button :style="{'color':scope.row.status == 2?'#F56C6C':'#67C23A'}" @click="saveSigna(scope.row)" type="text">{{scope.row.status == 2?'禁用':'启用'}}</el-button>
                            <!-- <el-button style="color:#67C23A" @click="saveSigna(2,scope.row.id)" v-if="scope.row.status == 3" type="text">启用</el-button>
                            <el-button style="color:#F56C6C" @click="saveSigna(3,scope.row.id)" v-if="scope.row.status == 2" type="text">禁用</el-button> -->
                            <el-button type="text" @click="onAdd(scope.row)">编辑</el-button>
                            <!-- <el-button style="color:#F56C6C" type="text">删除</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <pagination v-show="pagination.total>0" style="margin-top: 15px;" :total="pagination.total" :page.sync="pagination.current" :limit.sync="pagination.pageSize" @pagination="getLists" />
        </div>

        <!-- 新增编辑 -->
        <el-dialog :close-on-click-modal="false" :title="id?'编辑':'添加'+'短信签名'" :visible.sync="dialogVisible" width="800px" :before-close="handleClose">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
                <el-form-item label="签名名称:" prop="name">
                    <div class="flex-row-start">
                        <el-input style="width: 400px" v-model="ruleForm.name" placeholder="请输入签名名称"></el-input>
                        <div class="ml10">(3~12个字)</div>
                    </div>
                </el-form-item>
                <el-form-item label="选择门店" prop="shop_code">
                    <el-select style="width: 400px" v-model="ruleForm.shop_code" filterable placeholder="请选择选择门店">
                        <el-option v-for="(item,index) in storeList" :key="index" :label="item.shop_code+'-'+item.name" :value="item.shop_code">
                        </el-option>
                    </el-select>
                    <div class="cl-blue">如果选择平台，则表示连锁品牌未创建签名的，通用平台的签名；每个品牌仅允许创建一个；</div>
                </el-form-item>
                <el-form-item label="选择资质" prop="category">
                    <el-select style="width: 400px" v-model="ruleForm.category" filterable placeholder="请选择选择资质">
                        <el-option v-for="(item,index) in categoryList" :key="index" :label="item.title" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="上传资质文件" prop="file_url">
                    <el-upload style="width:80%" :show-file-list="false" :auto-upload="false" class="upload-demo" action="#" :on-change="handleChange">
                        <div class="flex">
                            <div>
                                <el-button size="small" type="primary">点击上传</el-button>
                            </div>
                            <div class="image_bos" v-if="ruleForm.file_url">
                                <div class="image_list">
                                    <img class="img" :src="ruleForm.file_url" alt="">
                                    <div class="demo_cover flex-row-center cursor-in">
                                        <i class="el-icon-remove-outline" style="color:#ffffff;font-size:24px" @click.stop="handleRemove()"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb;建议尺寸：70*70p</div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="备注:" prop="remarks">
                    <el-input style="width: 400px" type="textarea" v-model="ruleForm.remarks" placeholder="请输入备注"></el-input>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
            </span>
        </el-dialog>

        <el-image ref="previewImg" style="width: 0px; height: 0px" :src="elimage" :preview-src-list="elimageList" />
    </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
    components: {
        Pagination,
    },
    data() {
        return {
            tableData: [],
            dialogVisible: false,
            loading: false,
            statusList: [
                { title: '待审核', id: '1' },
                { title: '已审核/已启用', id: '2' },
                { title: '禁用', id: '3' },
            ],
            auditingStatus: [
                { title: '已申请/未审核', id: '1' },
                { title: '已通过', id: '2' },
                { title: '已驳回', id: '3' },
            ],
            search: {
                name: '', var: '', status: '', auditing_status: '',
            },

            id: null,
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
            },
            storeList: [],
            ruleForm: {
                name: '', category: '1', file_url: '', remarks: '', shop_code: '',
            },
            rules: {
                name: [
                    { required: true, message: '请输入签名名称', trigger: 'blur' }
                ],
                file_url: [
                    { required: true, message: '请上传资质文件', trigger: 'blur' }
                ],
                category: [
                    { required: true, message: '请选择选择资质', trigger: 'change' }
                ],
                remarks: [
                    { required: true, message: '请输入备注', trigger: 'blur' }
                ],
                shop_code: [
                    { required: true, message: '请选择选择门店', trigger: 'change' }
                ],
            },
            categoryList: [
                { title: '三证合一/营业执照', id: '1' },
                { title: '组织机构代码证', id: '2' },
                { title: '社会信用代码证', id: '3' },
                { title: '商标证书', id: '4' },
                { title: '网站备案证明', id: '5' },
                { title: '授权书', id: '6' },
                { title: '其它证明文件', id: '7' },
            ],

            elimage: '',
            elimageList: [],
        }
    },
    created() {
        this.getStore()
        this.getLists(1)
    },
    methods: {
        getLists(res) {
            if (res) {
                this.pagination.current = res
            }
            if (this.loading == true) return;
            this.loading = true;
            this.$http
                .api("api/sms/listSignature", {
                    name: this.search.name,
                    var: this.search.var,
                    status: this.search.status,
                    auditing_status: this.search.auditing_status,
                    limit: this.pagination.pageSize,
                    page: this.pagination.current,
                })
                .then((res) => {
                    this.tableData = res.data.data;
                    this.pagination.total = res.data.total;
                    this.loading = false;
                })
                .catch((res) => {
                    this.loading = false;
                });
        },
        onReset() {
            this.search = {
                name: '', var: '', status: '', auditing_status: '',
            }
            this.getLists(1)
        },
        onAdd(row) {
            if (row.id) {
                this.ruleForm = {
                    name: row.name, category: row.category + '', file_url: row.file_url, remarks: row.remarks, shop_code: row.shop_code,
                }
                this.id = row.id
            } else {
                this.ruleForm = {
                    name: '', category: '1', file_url: '', remarks: '', shop_code: '',
                }
            }
            this.dialogVisible = true
        },
        handleClose() {
            this.dialogVisible = false
        },
        //上传图片
        handleChange(file) {
            const isAccess = file.raw.type === 'image/jpeg' || file.raw.type === 'image/jpg' || file.raw.type === 'image/png';
            const isLt4M = file.size / 1024 / 1024 < 4;
            if (!isAccess) {
                return this.$message.error('上传图片只能是 JPG或PNG 格式!');
            }
            if (!isLt4M) {
                return this.$message.error('上传头像图片大小不能超过 4MB!');
            }
            let params = new FormData();  //   这步是很关键的，创建一个空对象赋值给fileNames
            params.append("file", file.raw);
            params.append("file_name", file.name);
            this.$http.upload(file.raw).then(res => {
                this.ruleForm.file_url = res.data.https_url
            })
        },
        handleRemove() {
            this.ruleForm.file_url = ''
        },
        getStore() {
            this.$http
                .api("api/common/shopCodeList", {})
                .then((res) => {
                    this.storeList = res.data;
                })
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const {
                        name, category, file_url, remarks, shop_code,
                    } = this.ruleForm
                    const datas = {
                        name, category, file_url, remarks, shop_code,
                    }
                    let url = 'api/sms/addSignature'
                    if (this.id) {
                        datas.id = this.id
                        url = 'api/sms/editSignature'
                    }
                    this.$http.api(url, datas).then(res => {
                        this.dialogVisible = false
                        this.getLists(1)
                    }).catch(res => {
                    })
                }
            })
        },
        saveSigna(row) {
            if (row.auditing_status == 2 && (row.status == 3 || row.status == 2)) {
                this.$http.api('api/sms/saveSignature', { id: id, status: row.status == 3 ? 2 : 3 }).then(res => {
                    this.getLists(1)
                }).catch(res => {
                })
            } else {
                this.$message.warning('审核状态通过后才可修改~');
            }
        },
        //图片放大
        handleView(data) {
            this.elimage = data
            this.elimageList = [data]
            this.$refs.previewImg.showViewer = true
        },
        nameCategory(id) {
            let name = ''
            this.categoryList.forEach(item => {
                if (item.id == id) {
                    name = item.title
                }
            })
            return name
        }
    }
}
</script>

<style lang="less" scoped>
.Sms1 {
    .image_bos {
        .image_list {
            height: 70px;
            width: 70px;
            border-radius: 5px;
            overflow: hidden;
            display: inline-block;
            margin-left: 10px;
            position: relative;
            .img {
                height: 70px;
                width: 70px;
            }
            .demo_cover {
                position: absolute;
                top: 0;
                left: 0;
                height: 0px;
                width: 0px;
                background: rgba(0, 0, 0, 0.6);
                overflow: hidden;
                cursor: pointer;
            }
        }
        .image_list:hover {
            .demo_cover {
                height: 70px;
                width: 70px;
            }
        }
    }
}

.file_url {
    width: 100px;
    height: 60px;
    border-radius: 4px;
    cursor: pointer;
}
</style>